<template>
  <div class="container-fluid mt-3">
    <Form @submit="addBroadcast" v-slot="{ errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb current_page="إضافة بث مباشر"/>
        <div class="controls-btns d-flex gap-2 mb-4">
          <button class="btn btn-publish" type="submit" @click="addBroadcast" :disabled="loading">إضافة</button>
        </div>
      </div>

      <el-card class="custom-card">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="تفاصيل البث" name="broadcast">
            <div class="row my-3">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="title">عنوان البث</label>
                  <Field
                      name="title"
                      id="title"
                      v-model="broadcast.title"
                      placeholder="عنوان البث"
                      class="form-control form-control-solid"
                      :rules="isRequired"
                      :class="{'border-red' : errors.title}"
                  />
                  <span class="text-error">{{ errors.title ? 'الرجاء ادخال عنوان البث' : '' }}</span>
                </div>

                <div class="form-group mt-3">
                  <label for="url">رابط البث</label>
                  <Field
                      name="url"
                      id="url"
                      v-model="broadcast.url"
                      placeholder="رابط البث"
                      class="form-control form-control-solid"
                      :rules="isRequired"
                      :class="{'border-red' : errors.url}"
                  />
                  <span class="text-error">{{ errors.url ? 'الرجاء ادخال رابط البث' : '' }}</span>
                </div>

                <div class="form-group mt-3">
                  <label for="type">نوع البث</label>
                  <select class="form-select" id="type" v-model="broadcast.type">
                    <option value=""></option>
                    <option :value="option.value" :key="option.value" v-for="option in options">{{option.name}}</option>
                  </select>
                </div>

                <el-checkbox v-model="broadcast.active" class="me-0 my-3 rounded-0" label="نشط؟"
                             border></el-checkbox>

              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {Field, Form} from "vee-validate";
import {createBroadcast} from "@/services/live_broadcat";

export default {
  name      : "Add_broadcast",
  components: {
    Breadcrumb,
    Form,
    Field
  },
  data()
  {
    return {
      loading: false,
      activeTab: "broadcast",
      broadcast: {
        title : '',
        url   : '',
        type  : 'facebook',
        active: ''
      },
      options  : [
        {name: 'facebook', value: 'facebook'},
        {name: 'youtube', value: 'youtube'}
      ]

    }
  },
  methods: {
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },
    addBroadcast()
    {
      if(this.broadcast.title.length && this.broadcast.url.length) {
        this.loading = true
        createBroadcast(this.broadcast)
            .then(res=> {
              this.loading = false
              console.log('test')
            })
            .catch(()=>{
              this.loading = false
            })
      }
    }
  }
}
</script>

<style>
.custom-card {
  min-height: 300px;
}

.el-tabs__nav {
  float: right;
}

.el-tabs__item {
  padding-right: 0;
  padding-left: 20px !important;
}

.el-checkbox.is-bordered {
  margin: 0;
  border-radius: 0;
  height: 42px;
}

.el-checkbox__label {
  padding-right: 8px;

}

</style>
